import React from 'react'
import './Tapahtumat.css'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { Tapahtuma, TapahtumaRivi, luoUusiTapahtuma } from './Tapahtuma'
import { TapahtumaMuokkaus } from './TapahtumaMuokkaus'
import { development } from '../Environment'

const onkoKesaAika = ():boolean => {
    const nyt = new Date()
    const tammi = new Date(nyt.getFullYear(), 0, 1).getTimezoneOffset()
    const heina = new Date(nyt.getFullYear(), 6, 1).getTimezoneOffset()
    return Math.max(tammi, heina) !== nyt.getTimezoneOffset()
}

const plusTunteja = (aika:Date, tunteja:number) => {
  const tulos = new Date(aika)
  tulos.setTime(tulos.getTime() + tunteja*1000*60*60)
  return tulos
}

const korjaaAika = (tapahtuma:Tapahtuma) => {
  const tuntiMuutos = onkoKesaAika() ? 4 : 3
  return {
    ...tapahtuma,
    aika: plusTunteja(new Date(tapahtuma.aika), tuntiMuutos),
    ilmestymisaika: new Date(tapahtuma.ilmestymisaika),
  }
}

const korjaaTallennusAika = (tapahtuma:Tapahtuma) => {
  const tuntiMuutos = onkoKesaAika() ? 1 : 0
  return {
    ...tapahtuma,
    aika: plusTunteja(new Date(tapahtuma.aika), tuntiMuutos)
  }
}

type TapahtumatProps = { 
  muokkaus: boolean,
  setLadattu: (ladattu:boolean)=>void 
}

const Tapahtumat: React.FC<TapahtumatProps> = ({ muokkaus, setLadattu }) => {
  const [events, setEvents] = useState<Tapahtuma[]>([])
  const [muokattava, setMuokattava] = useState<Tapahtuma|null>(null)

  const haeTapahtumat = () => {
    if(development()){ setLadattu(true) }
    axios.get('/tapahtumat').then(vastaus => {
      const tapahtumat = vastaus.data.map(korjaaAika)
        .sort((a:Tapahtuma, b:Tapahtuma) => a.aika.getTime() - b.aika.getTime())
      setEvents(tapahtumat)
      setMuokattava(null)
      setLadattu(true)
    })
  }

  useEffect(haeTapahtumat, [])

  const uusi = () => setMuokattava(luoUusiTapahtuma())
  const tallennus = () => {
    korjaaTallennusAika(muokattava!)
    axios.post<Tapahtuma>('/tapahtumat', muokattava)
      .then(haeTapahtumat)
  }
  const poisto = () => {
    if(muokattava && muokattava.id){
      axios.post(`/tapahtumat/poista/${muokattava.id}`)
        .then(haeTapahtumat)
    }
  }
  const peruutus = () => setMuokattava(null)

  return (
    <div id="tapahtumat">
      { muokattava ?
        <TapahtumaMuokkaus tapahtuma={muokattava} setTapahtuma={setMuokattava} 
            {...{tallennus, peruutus, poisto}}
        />
      :
        <> 
          <div id="tapahtumaalue">
            { events.map(tapahtuma => 
                <TapahtumaRivi {...{tapahtuma, muokkaus}} muokkaaTapahtumaa={setMuokattava} key={tapahtuma.id || ''} />
              ) }
          </div>
          <div id="tervetuloa">Tervetuloa tilaisuuksiin!</div>
          {/*<div id="eikokouksia">Koronan takia ei kokouksia toistaiseksi</div> */}
          { muokkaus &&
            <div id="uusitapahtumapainike" onClick={uusi} title="Lisää tapahtuma">
              +
            </div>
          }
        </>
      }
    </div>
  )
}

export default Tapahtumat