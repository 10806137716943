
import React from 'react'
import '../lomakkeet.css'
import './YhteydenOtto.css'

const EMAIL_RAJAPINTA = 'https://script.google.com/macros/s/AKfycbwhtQomE6RCikeCL1tDmyslbq_0t0t705cc6LquMM2NihQbG6zeEvjlg5RKYUjXRGywRw/exec'

type YhteysProps = {

}

export const YhteydenOtto: React.FC<YhteysProps> = () => {
  return (
    <div className="yhteydenotto">
      <p>Esimerkiksi palaute, esirukouspyyntö tai yhteystietojen muutosilmoitus</p>
      <div className="kentta">
        <label htmlFor="nimiruutu">Nimi:</label>
        <input type="text" id="nimiruutu"/>
      </div>
      <div className="kentta">
        <label htmlFor="nimiruutu">Sähköposti:</label>
        <input type="text" id="emailruutu"/>
      </div>
      <div className="kentta">
        <label htmlFor="viestiruutu">Viesti:</label>
        <textarea id="viestiruutu" cols={50} rows={7}></textarea>
      </div>
      <button>Lähetä</button>
    </div>
  )
}
