import React from 'react'
import './LoginIlmoitus.css'
import { useState } from 'react'
import axios from 'axios'
import kynakuva from '../img/kyna.png'

const LoginIlmoitus:React.FC<LoginIlmoitusProps> = ({kayttaja, muokkaus}) => {
  const [pyydetty, setPyydetty] = useState(false)

  const pyydaOikeutta = () => {
    axios.post('/pyydaoikeutta').then(() => {
      setPyydetty(true)
    })
  }

  if(!kayttaja){ return null }
  return (
    <div id="loginilmoitus">
      Kirjautunut
      {
        muokkaus ?
          ' muokkaajana'
          : pyydetty ? 
            ' (pyyntöä käsitellään...)'
            : <button id="pyynto" onClick={pyydaOikeutta}>
                Pyydä muokkausoikeutta
              </button>
      }
      { muokkaus && <img src={kynakuva} alt="lyijykynä"/> }
    </div>
  )
}

type LoginIlmoitusProps = {
  kayttaja: string,
  muokkaus: boolean
}

export default LoginIlmoitus