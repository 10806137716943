import React, { ChangeEvent } from 'react'
import { intRange } from '../Utils'

type KelloProps = {
  aika: Date,
  asetaAika: (aika:Date)=>void
}

export const KellonajanMuokkaus: React.FC<KelloProps> = ({aika, asetaAika}) => {
  const asetaTunnit = ({target}:ChangeEvent<HTMLSelectElement>) => asetaAika(
    new Date(new Date(aika).setUTCHours(parseInt(target.value)))
  )
  const asetaMinuutit = ({target}:ChangeEvent<HTMLSelectElement>) => asetaAika(
    new Date(new Date(aika).setUTCMinutes(parseInt(target.value)))
  )

  return (
    <>
      <select 
          value={aika.getUTCHours().toString().padStart(2, '0')} 
          onChange={asetaTunnit}>
        { 
          intRange(7, 25)
            .map(i => i % 24) 
            .map(i => i.toString())
            .map(i => i.padStart(2, '0'))
            .map(i => <option key={i}>{i}</option>)
        }
      </select>
      <select 
          value={aika.getUTCMinutes().toString().padStart(2, '0')} 
          onChange={asetaMinuutit}>
        <option value="00">00</option>
        <option value="30">30</option>
      </select>
    </>
  )
}
