
import React from 'react'

export type Viesti = {
  teksti: string,
  virhe: boolean
}

export const TyhjaViesti = { teksti: '', virhe:false }

type ViestiProps = { viesti: Viesti }

export const ViestiRuutu: React.FC<ViestiProps> = ({ viesti }) => {
  return viesti.teksti ? 
      <span className={ viesti.virhe ? "errorMessage" : "infoMessage" }>
        {viesti.teksti}
      </span>
  : <></>
}