
type VerseOfTheDay = {
  text: string,
  verse: string,
  image: any
}

type VerseDescription = [
  string, string, string
]

const getVerse = (verse:VerseDescription):VerseOfTheDay => {
  const [ text, passage, image ] = verse
  return {
    text, 
    verse: passage,
    image: image != '' && require(`../img/sana/${image}.png`)
  }
}

const verseOfTheDay = ():VerseOfTheDay => {
  const index = dayOfYear() + 7 
  const description = verses[index % verses.length]
  if(description){
    return getVerse(description)
  }else{
    return { text: '', verse: '', image: '' }
  }
}

const dayOfYear = () => {
  const yearStart = new Date(new Date().getFullYear(), 0, 0).getTime()
  return Math.floor((new Date().getTime() - yearStart) / (1000*60*60*24))
}

const verses:VerseDescription[] = [ 
    //maaliskuu
]

export default verseOfTheDay

//VANHAT JAKEET

/* maaliskuun puoliväli
 ['Etsikää Herraa silloin kun hänet löytää voidaan','Jes 55:6','rukous2'], //su 14.3.
  ['Joka katsoo naista himoiten, on sydämessään jo tehnyt huorin','Matt 5:28','silma'], //ma 15.3.
  ['Ne, jotka Herraa odottavat, saavat uuden voiman', 'Jes 40:31', 'virta'], //ti 16.3.
  ['Jeesus sanoi hänelle: "Minä olen ylösnousemus ja elämä"', 'Joh 11:25', 'hauta2'], //ke 17.3.
  ['Jumala loi ihmisen omaksi kuvakseen', '1 Moos 1:27', 'piirros'], //to 18.3.
  ['Minä tulen pian', 'Ilm 22:7', 'pilvet'], //pe 19.3.
  ['Hän päästää sinut linnustajan ansasta ja surmaavasta rutosta', 'Ps 91:3', 'virus'], //la 20.3.
  ['Teidän pahat tekonne erottavat teidät Jumalastanne', 'Jes 59:2', 'aita2'], //su 21.3.


/* maaliskuun alku
  ['Ette voi palvella sekä Jumalaa että mammonaa','Luuk 16:13','arkku'], //pe 5.3.
  ['Häntä pelkääviltä ei mitään puutu','Ps 34:10','lautanen'], //la 6.3.
  ['Hän joka on alkanut teissä hyvän työn, vie sen päätökseen','Fil 1:6','loppu'], //su 7.3.
  ['Sydän on petollisempi kaikkea muuta ja parantumattoman paha','Jer 17:9','paha'], //ma 8.3.
  ['Älkää menneistä välittäkö. Katso minä teen uutta!','Jes 43:19','uusi'], //ti 9.3.
  ['Loistakoon teidänkin valonne ihmisten edessä','Matt 5:16','lamppu'], //ke 10.3.
  ['Olkoon kenkinä jaloissanne alttius rauhan evankeliumia kohtaan','Ef 6:15','kenka'], //to 11.3.
  ['Älkää rakastako maailmaa, älkääkä sitä, mikä maailmassa on','1 Joh 2:15','pallo'], //pe 12.3.
  ['Jeesus meni temppeliin ja ajoi ulos kaikki, jotka myivät ja ostivat siellä','Matt 21:12','kolikot'], //la 13.3.
  ['Etsikää Herraa silloin kun hänet löytää voidaan','Jes 55:6','rukous2'], //su 14.3.
*/

/* helmikuun loppu
['Minä annan teille tulevaisuuden ja toivon', 'Jer 29:11', 'toivo'], //ma 15.2.
  ['Nyt minä tiedän, ettei Jumalaa ole muualla maan päällä kuin Israelissa','2 Kun 5:15','Israel'], //ti 16.2.
  ['Kiittäkää joka tilanteessa','1 Tess 5:18','rukous'], //ke 17.2.
  ['Ette voi palvella sekä Jumalaa että mammonaa','Luuk 16:13','arkku'], //to 18.2.
  ['Jumalan rakkaus on vuodatettu meidän sydämiimme Pyhän Hengen kautta','Room 5:5','sydan3'], //pe 19.2.
  ['Metuselahin koko elinaika oli 969 vuotta','1 Moos 5:27','ika'], //la 20.2
  ['Jeesus ilmestyy taivaasta väkevien enkeleittensä kanssa tulenliekissä','2 Tess 1:7','aurinko'], //su 21.2.
  ['Tämä päivä on hyvän sanoman päivä','2 Kun 7:9','torvi'], //ma 22.2.
  ['En minä ole tullut kutsumaan vanhurskaita vaan syntisiä','Matt 9:13','omena'], //ti 23.2.
  ['Minä annan teille uuden sydämen ja uuden hengen','Hes 36:26','sydan4'], //ke 24.2.
  ['Hän pyyhkii kaikki kyyneleet heidän silmistään','Ilm 21:4','kyynel2'], //to 25.2.
  ['Lootin vaimo katsoi taakseen ja muuttui suolapatsaaksi','1 Moos 19:26','patsas'], //pe 26.2.
  ['Isämme eivät ole totelleet tämän kirjan sanoja','2 Kun 22:13','rulla'], //la 27.2.
  ['Säilyköön koko teidän henkenne, sielunne ja ruumiinne nuhteettomana','1 Tess 5:23','seisoa'], //su 28.2.
  ['Vallitkoon teidän sydämissänne Kristuksen rauha','Kol 3:15','lintu4'], //ma 1.3.

*/

/* helmikuun alku 2021

  ['Pidän sen toivon Jumalaan, että on oleva ylösnousemus', 'Apt 24:15', 'aurinko2'], //ma 25.1.
  ['Kolmisäikeinen lanka ei helposti katkea', 'Saarn 4:11', 'naru'], //ti 26.1.
  ['Minä annan teille tulevaisuuden ja toivon', 'Jer 29:11', 'toivo'], //ke 27.1.
  ['Karttakaa kaikenlaista pahaa', '1 Tess 5:22', 'kaarm2'], //to 28.1.
  ['Hän halkaisi meren ja vei heidät sen läpi', 'Ps 78:13', 'aalto'], //pe 29.1.
  ['Tehkää kaikki Herran Jeesuksen nimessä, kiittäen isää Jumalaa', 'Kol 3:17', 'nimi'], //la 30.1.
  ['Koko Raamattu on syntynyt Jumalan Hengen vaikutuksesta', '2 Tim 3:16', 'raamattu5'], //su 31.1.
  ['Ne, jotka suunnittelevat sieluni tuhoamista, joutuvat maan syvyyksiin', 'Ps 63:10', 'jousi'], //ma 1.2.
  ['Kunnioita isääsi ja äitiäsi, että kauan eläisit', '2 Moos 20:12', 'vanhemmat'], //ti 2.2.
  ['Hän nöyryytti itsensä ja oli kuuliainen kuolemaan asti', 'Fil 2:8', 'piikit'], //ke 3.2.
  ['Meidän tulee uskoa hänen Poikansa Jeesuksen Kristuksen nimeen', '1 Joh 3:23', 'risti4'], //to 4.2.
  ['Joka kuopan kaivaa, se itse siihen suistuu', 'Saarn 10:8', 'lapio3'], //pe 5.2.
  ['Joka runsaasti kylvää, se myös runsaasti niittää', '2 Kor 9:6', 'vilja'], //la 6.2.
  ['Salomon viisautta tultiin kuulemaan kaikista kansoista', '1 Kun 4:34', 'kruunu'], //ma 8.2.
  ['Jeesuksen kanssa ristiinnaulittiin kaksi rosvoa', 'Matt 27:38', 'naula'], //su 7.2.
  ['Katso, hän tulee pilvissä! Kaikkien silmät näkevät hänet', 'Ilm 1:7', 'pilvi2'], //ti 9.2.
  ['Valvokaamme ja olkaamme raittiit', '1 Tess 5:6', 'kello'], //ke 10.2.
  ['Hän sanoi: "Jeesus, muista minua, kun tulet valtakuntaasi"', 'Luuk 24:42', 'koriste'], //to 11.2.
  ['Lähetä leipäsi vetten yli, sillä ajan mittaan saat sen takaisin', 'Saarn 11:1', 'leipa2'] //pe 12.2.

*/

//tammikuu 2021

/*
  ['Te olette Jumalan temppeli ja Jumalan Henki asuu teissä', '1 Kor 3:16', 'temppeli'], // ma 11.1.
  ['Panen toivoni lupaukseen, jonka Jumala on antanut meidän isillemme', 'Apt 26:6', 'toora'], //ti 12.1.
  ['Jos sokea taluttaa sokeaa, molemmat putoavat kuoppaan', 'Matt 15:14', 'pudota'], //ke 13.1.
  ['Hän, joka Israelia varjelee, ei torku eikä nuku', 'Ps 121:4', 'Israel'], //to 14.1.
  ['Leijona Juudan heimosta, Daavidin juurivesa on voittanut', 'Ilm 5:5', 'leijona2'], //pe 15.1.
  ['Jokaisen paikan, jota jalkanne tallaa, minä annan teille', 'Joos 1:3', 'jalki'], //la 16.1.
  ['En enää elä minä, vaan Kristus minussa', 'Gal 2:20', 'kynttila'], //su 17.1.
  ['Tahdon kertoa kaikista sinun ihmeteoistasi', 'Ps 9:2', 'venus'], //ma 18.1.
  ['Hän nousi ja nuhteli tuulia ja merta ja tuli aivan tyven', 'Matt 8:26', 'aalto3'], //ti 19.1.
  ['Missä on paljon sanoja, sieltä ei syntiä puutu', 'Snl 10:19', 'kupla'], //ke 20.1.
  ['Usko ilman tekoja on kuollut', 'Jaak 2:26', 'hauta'], //to 21.1.
  ['Jeesus sanoi hänelle: nouse, ota vuoteesi ja kävele', 'Joh 5:8', 'vuode'], //pe 22.1.
  ['Näin Daavid voitti filistealaisen vain linko ja kivi apunaan', '1 Sam 17:50', 'kivet'], //la 23.1.
  ['Ja kaiken, mitä te anotte rukouksessa, uskoen, te saatte', 'Matt 21:22', 'rukous3'], //su 24.1.
*/

//joulukuu

/*
  ['Jeesus sanoi: "Tule ja seuraa minua"', 'Mark 10:21', 'kyltti'],  //ke 2.12.
  ['Taivasten valtakunta on lasten kaltainen', 'Matt 19:14', 'lapsi'],  //to 3.12.
  ['Minun ruokani on se, että teen lähettäjäni tahdon', 'Joh 4:32', 'ruoka'],  //pe 4.12.
  ['Älkää kootko itsellenne aarteita maan päälle', 'Matt 6:19', 'arkku'],  //la 5.12.
  ['Kiitetty olkoon Herra, joka on pelastanut teidät faraon kädestä', '2 Moos 18:10', 'suomi2'], // su 6.12.
  ['Taivaallinen Isä antaa hyvää niille, jotka sitä häneltä anovat', 'Matt 7:11', 'kala'], //ma 7.12.
  ['Katso, Jumalan Karitsa, joka ottaa pois maailman synnin', 'Joh 1:29', 'piikit'], //ti 8.12.
  ['Herran pelko on viisauden alku', 'Ps 111:10', 'viisaus'], //ke 9.12.
  ['Hänen totuutensa on kilpi ja suojus', 'Ps 91:4', 'kilpi2'], //to 10.12.
  ['Jumalan valtakunta ei ole puheissa vaan voimassa', '1 Kor 4:20', 'salama'], //pe 11.12.
  ['Vastustakaa Paholaista, niin hän pakenee luotanne', 'Jaak 4:7', 'dragon'], //la 12.12.
  ['Katso, neitsyt tulee raskaaksi ja synnyttää pojan', 'Luuk 1:23', 'maria2'], //su 13.12.
  ['Ruoho kuihtuu ja kukka varisee, mutta Herran sana pysyy iäti', '1 Piet 1:25', 'ruoho'], //ma 14.12.
  ['Hän oli täynnä armoa ja totuutta', 'Joh 1:14', 'langat2'], //ti 15.12.
  ['Ei ihminen elä ainoastaan leivästä', 'Luuk 4:4', 'leipa'], //ke 16.12.
  ['Te löydätte lapsen, joka makaa kapaloituna seimessä', 'Luuk 2:12', 'seimi'], //ke 16.12.
  ['Autuaita ovat hengessään köyhät, heidän on taivasten valtakunta', 'Matt 5:3', 'linna'], //to 17.12.
  ['Sinä osoitat Jaakobille uskollisuutta, Aabrahamille armoa', 'Miika 7:20', 'menora'], //pe 18.12.
  ['Yli kaiken varottavan varjele sydämesi', 'Snl 4:23', 'sydan'], //la 19.12.
  ['Seuratkaa minua, niin minä teen teistä ihmisten kalastajia', 'Matt 4:19', 'onki'], //su 20.12.
  ['Valon hedelmä ilmenee hyvyytenä, vanhurskautena ja totuutena', 'Ef 5:9', 'hedelma'], //ma 21.12.
  ['Se, jolla on ymmärrystä, pelkää sinun nimeäsi, Herra', 'Miika 6:9', 'taulut'], //ti 22.12.
  ['Sillä Jumalalle ei mikään ole mahdotonta', 'Luuk 1:37', 'koriste'], //ke 23.12.
  ['Kansa, joka pimeydessä vaeltaa, näkee suuren valon', 'Jes 9:1', 'kirkkaus'], // to 24.12.
  ['Sillä lapsi on meille syntynyt, poika on meille annettu', 'Jes 9:5', 'lapsi'], // pe 25.12.
  ['Menkäämme nyt Beetlehemiin katsomaan sitä, mikä on tapahtunut', 'Luuk 2:15', 'tahti2'], // la 26.12.
  ['Kaikki maan ääret saavat nähdä meidän Jumalamme antaman pelastuksen', 'Jes 52:10', 'maapallo'], // su 27.12.
  ['Pankaamme pois kaikki, mikä meitä painaa', 'Heb 12:1', 'ketju'], // ma 28.12.
  ['Sinun kansasi on minun kansani ja sinun Jumalasi on minun Jumalani', 'Ruut 1:16', 'ruut'], // ti 29.12.
  ['Rauha ihmisten kesken, joita kohtaan hänellä on hyvä tahto', 'Luuk 2:14', 'yhteys'], // ke 30.12.
  ['Hän on hyvä kiittämättömille ja pahoille', 'Luuk 6:35', 'aurinko'], // to 31.12.
  ['Hän on säätänyt ihmisille määräajat ja asumisen rajat', 'Apt 17:26', 'suomi'], // pe 1.1.
  ['Ahkeruus on ihmiselle arvokas omaisuus', 'Snl 12:27', 'lapio3'], // la 2.1.
  ['Synnin palkka on kuolema', 'Room 6:23', 'vaaka'], // su 3.1.
  ['Ihminen näkee ulkomuodon, mutta Herra näkee sydämen','1 Sam 16:7','sydan'], // ma 4.1.
  ['Hänessä tulee vanhurskaaksi jokainen joka uskoo', 'Apt 13:39', 'kukka'], // ti 5.1.
  ['Mies jättäköön isänsä ja äitinsa ja yhtyköön vaimoonsa', '1 Moos 2:24', 'liitto2'], // ke 6.1.
  ['Se portti on ahdas ja tie kaita, joka vie elämään', 'Matt 7:14', 'ovi'], // to 7.1.
  ['Nooa meni arkkiin ja vedenpaisumus tuli', 'Luuk 17:27', 'arkki'], // pe 8.1.
  ['Herra on lähellä niitä, joilla on särkynyt sydän', 'Ps 34:19', 'sydan2'], // la 9.1.
  ['Kaikki, mitä Jumala tekee, pysyy ikuisesti', 'Saarn 3:14', 'vuori'], // su 10.1.

*/
 //2.11.2020
  /*'Hänen käskynsä eivät ole raskaat#hymy',
  'Herran asetukset ovat oikeat, ne ilahduttavat sydämen#asetukset',
  'Eloa on paljon, mutta työmiehiä vähän#vilja',
  'Niinkuin Isä on lähettänyt minut, minä lähetän teidät#maapallo',
  'Jumalan valtakunta on tullut lähelle#enkeli',
  'Sinulla on iankaikkisen elämän sanat#kupla',
  //kuvat puuttuu!
  'Rakasta lähimmäistäsi niinkuin itseäsi#raamattu',
  'Hänestä lähti voima, joka paransi kaikki#raamattu', //kainalosauva tms...
  'Kristus kuoli meidän puolestamme, kun me vielä olimme syntisiä#risti2', //ti 10.11.
  'Minä en häpeä evankeliumia#banneri', //ke 11.11.
  //...*/

  //7.10.2020
/*  'Hän paljastaa syvät ja salatut asiat#envelope',
  'Jumalan sana on elävä ja voimallinen#raamattu2',
  'Hän on lähettänyt minut julistamaan vangituille vapautusta#kahle2',
  'Me mietiskelemme armoasi sinun temppelissäsi#srk',
  'Niin kuin oli Nooan päivinä, niin on oleva myös Ihmisen Pojan päivinä#arkki',
  'Sinä rakastat vanhurskautta ja vihaat laittomuuta#valo',
  //19.10.
  'Hyvä paimen antaa henkensä lammasten edestä#risti',
  'Hän johdattaa meidät kuoleman yli#silta',
  'Joka ottaa vastaan sen, jonka minä lähetän, ottaa vastaan minut#kaaro',
  'Hänen laupeutensa pysyy polvesta polveen häntä pelkääville#sun',
  'Herra on kuningas aina ja ikuisesti#kruunu2',
  'Sinä unohdit Jumalan, joka antoi sinulle elämän#sunset',
  'Jumala pelasti hänet kaikista ahdistuksista#kukka',
  'Riisu kengät jalastasi, sillä paikka jossa seisot, on pyhää maata#pensas',
  'Sinä kudoit minut kokoon äitini kohdussa#kohtu',
  'Samalla sanalla on nykyiset taivaat ja nykyinen maa säästetty tulelle#poltto',
  'Eikö minun käteni ole tehnyt tätä kaikkea#maa',
  'Jeesuksen veri puhdistaa meidät kaikesta synnistä#veri',
  'Minä olen tullut tuomaan elämän ja yltäkylläisyyden#elama',
  // 1.11.
  'Sillä ilman minua te ette voi mitään tehdä#rukous',
  'Hänen käskynsä eivät ole raskaat#hymy'*/


  /*
  'Parempi on luottaa Herraan, kuin turvata ihmisiin',
  'Jumalan armolahja on iankaikkinen elämä Kristuksessa Jeesuksessa',
  'Autuaita ne, jotka uskovat, vaikka eivät näe',
  'Alussa Jumala loi taivaan ja maan',
  'Jumalani, monet ovat sinun tekemäsi ihmeet',
  'Totuuden Henki johdattaa teidät kaikkeen totuuteen',
  'Tee askeleeni vakaiksi sanallasi, ettei mikään vääryys hallitsisi minua',
  'Odotamme hengessä uskon kautta sitä vanhurskautta, jota toivomme',
  'Minulle oli hyväksi, että minut nöyryytettiin, näin opin sinun lakisi',
  'Etsikää ennen kaikkea Jumalan valtakuntaa ja hänen vanhurskauttaan',
  'Ihmisen Poika on tullut etsimään ja pelastamaan sitä, mikä oli kadoksissa',
  'Tehkää siis parannus ja kääntykää, että syntinne pyyhittäisiin pois',
  'Jumala on antanut pakanoillekin mielenmuutkosen, joka koituu elämäksi',
  'Tulette tuntemaan totuuden ja totuus tekee teistä vapaita',
  'Olen kätkenyt sanasi sydämeeni, etten tekisi syntiä sinua vastaan',
  'Herran pelko on pahan vihaamista',
  'Sinä Jerusalem olet rakennettu kaupungiksi, joka on täysin yhtenäinen',
  'Suuret ovat Herran teot',
  'Sillä sinun luonasi on elämän lähde, sinun valossasi me näemme valon',
  'Sinun sanasi on jalkojeni lamppu ja valo minun polullani',
  'Kuka tämä on, joka antaa syntejäkin anteeksi?',*/


  /* 'Mikä on ihmisille mahdotonta, se on mahdollista Jumalalle',
  'Apu minulle tulee Herralta, taivaan ja maan luojalta',
  'Jos Herra ei taloa rakenna, turhaan sen rakentajat näkevät vaivaa',
  'Herra rakentaa Jerusalemin, hän kokoaa Israelin karkotetut',
  'Hän parantaa ne joilla on särkynyt sydän ja sitoo heidän haavansa',
  'Vanhurskaus kansan korottaa, mutta synti on kasojen häpeä',
  'Herran nimi on vahva torni',
  'Muista luojaa nuoruudessasi',
  'Jumalan mielen mukainen murhe johtaa parannukseen',
  'Tämä aarre on meillä saviastioissa',
  '"Kenen te sanotte minun olevan?" Jeesus kysyi',
  'Joka uskoo ja kastetaan, se pelastuu',
  'Niin kuin oli Nooan päivinä, niin on oleva myös Ihmisen Pojan päivinä',
  'Jumalan valtakunta on vanhurskautta, rauhaa ja iloa Pyhässä Hengessä',
  'Te saatte voiman, kun Pyhä Henki tulee teidän päällenne',
  'Rakastakaa toisianne, niin kuin minä olen rakastanut teitä',
  '...eikä kukaan ota teiltä pois iloanne',
  'Maailmassa teillä on ahdistus, mutta olkaa rohkeat',
  //UUDET 18.8.
  'Sydämen kyllyydestä suu puhuu'*/
