
import React from 'react'

const PrivacyPolicy:React.FC = () => (

  <div>
     <h2>Facebook-tietojen käyttö</h2>
     <p>
      Sivustolle on mahdollista kirjautua facebookin avulla.
      Kirjautuminen toteutetaan facebookin generoiman sovelluskohtaisen käyttäjä id:n avulla.
      Jos käyttäjä tekee muokkausoikeuspyynnön, hänen Facebook-nimensä tallentuu pyynnön yhteyteen tietokantaan. 
      Rautallammin Helluntaiseurakunta ei muuten käsittele henkilötietoja sivustolla.
    Yhteys on suojattu TLS:llä.
     </p>
  </div>
)

export default PrivacyPolicy