
import React, { ChangeEvent } from 'react'
import { Tapahtuma } from './Tapahtuma'
import '../lomakkeet.css'
import kyna from '../img/muokkaus.png'
import { KellonajanMuokkaus } from './KellonajanMuokkaus'
import { useState } from 'react'

type TapahtumaMuokkausProps = { 
  tapahtuma: Tapahtuma,
  setTapahtuma: (tapahtuma:Tapahtuma)=>void,
  tallennus: ()=>void,
  peruutus: ()=>void,
  poisto: ()=>void
}

export const TapahtumaMuokkaus: React.FC<TapahtumaMuokkausProps> 
  = ({ tapahtuma, setTapahtuma, tallennus, peruutus, poisto }) => {

  const [odotusTeksti, setOdotusTeksti] = useState('')

  const asetaAika = (aika:Date) => setTapahtuma({...tapahtuma, aika})
  const asetaPvm = ({target}:ChangeEvent<HTMLInputElement>) => {
    let aika = new Date(target.value)
    if(isNaN(aika.getTime())){ return } 
    aika = new Date(aika.setUTCHours(tapahtuma.aika.getUTCHours()))
    aika = new Date(aika.setUTCMinutes(tapahtuma.aika.getUTCMinutes()))
    asetaAika(aika)
  }
  const setOtsikko = ({target}:ChangeEvent<HTMLInputElement>) => setTapahtuma({
    ...tapahtuma, otsikko: target.value
  })
  const setKuvaus = ({target}:ChangeEvent<HTMLTextAreaElement>) => setTapahtuma({
    ...tapahtuma, kuvaus: target.value
  })
  const eiKokousta = tapahtuma.otsikko === 'Ei kokousta'
  const muutaEiKokousta = () => setTapahtuma({
    ...tapahtuma, otsikko: eiKokousta ? '' : 'Ei kokousta'
  }) 

  const onkoUusi = !tapahtuma.id

  const suorita = (toiminto:()=>void, odotusTeksti:string) => () => {
    setOdotusTeksti(odotusTeksti)
    toiminto()
  }

  return (
    <div id="tapahtumamuokkaus">
      <div id="muokkausotsikko">
        <img src={kyna} alt="muokkauskynä"/>
        {onkoUusi ? 'Uusi tapahtuma' : 'Tapahtuman muokkaus'}
      </div>
      <div className="kentta">
        <label htmlFor="tapahtumapvmvalitsin">Aika:</label>
        <input id="tapahtumapvmvalitsin" type="date" onChange={asetaPvm}
            value={tapahtuma.aika.toISOString().slice(0,10)} />
      </div>
      <div className="kentta">
        <label htmlFor="tapahtumaaikavalitsin"></label>
        <span id="tapahtumaaikavalitsin">
          <KellonajanMuokkaus aika={tapahtuma.aika} asetaAika={asetaAika} />
        </span>
      </div>
      <div className="kentta">
        <label htmlFor="eikokoustaruutu"></label>
        <input id="eikokoustaruutu" type="checkbox"
            checked={eiKokousta} onChange={muutaEiKokousta} />
        <label htmlFor="eikokoustaruutu" id="eikokoustalabeli">Ei kokousta</label>
      </div>
      <div className="kentta">
        <label htmlFor="tapahtumaotsikkoruutu">Otsikko:</label>
        <input id="tapahtumaotsikkoruutu" value={tapahtuma.otsikko}
            onChange={setOtsikko} disabled={eiKokousta} />
      </div>
      <div className="kentta">
        <label htmlFor="tapahtumakuvausalue">Lisätiedot:</label>
        <textarea id="tapahtumakuvausalue" rows={3} cols={24}
            value={tapahtuma.kuvaus} onChange={setKuvaus}></textarea>
      </div>

      { odotusTeksti ?
        <div id="odotusteksti">{odotusTeksti}</div>
       :
        <div id="tapahtumapainikkeet">
          <button onClick={suorita(tallennus, 'Tallennetaan...')}>
            Tallenna
          </button>
          { !onkoUusi && 
            <button onClick={suorita(poisto, 'Poistetaan...')}>
              Poista
            </button> 
          }
          <button onClick={peruutus}>Peruuta</button>
        </div>
      }
    </div>
  )
}
