
import React from 'react'
import './Naytto.css'
import { useState, useEffect } from 'react'
import verseOfTheDay from './BibleText'

export const Naytto:React.FC = () => {
  const [verse, setVerse] = useState('')
  const [picture, setPicture] = useState('')
  const [fontSize, setFontSize] = useState('10px')

  useEffect(() => {
    const { text, image } = verseOfTheDay()
    setVerse(text)
    setFontSize(`${Math.floor(19 - 2.65*Math.log(text.length))}vh`)
    setPicture(image)
  }, [])

  return (
    <div className="screen">
      <div className="bibletext" style={{ fontSize }}>
        <div className="btitle">{verse && 'Päivän Sana'}</div>
        <div className="pictureRow">
          <img className="bpicture" src={picture}/>
        </div>
        <div className="verse">{verse}</div>
      </div>
      <div className="curtain curtainLeft"></div>
      <div className="curtain curtainRight"></div>
    </div>
  )
}