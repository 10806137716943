import React, { useRef, useState, useEffect } from 'react'
import axios, { AxiosResponse } from 'axios'
import { setToken } from './TokenPalvelu'
import './Login2.css'

type LoginVastaus = { token: string }
type LoginState = 'Initial' | 'Loading' | 'Logged' | 'ShowLogin' | 'Wrong'

export const Kirjautuminen:React.FC<LoginProps> = ({ setUser, setMuokkaus }) => {
  const [ salasana, setSalasana ] = useState('')
  const [ state, setState ] = useState<LoginState>('Initial')
  const inputRef = useRef<HTMLInputElement>(null)

  const kirjaudu = () => {
    setState('Loading')
    axios.post('/kirjaudu', salasana)
      .then(({data}:AxiosResponse<LoginVastaus>) => {
        console.log('TOKEN' ,data.token)
        if(data.token){
          setToken(data.token)
          setMuokkaus(true)
          setState('Logged')
          setUser('kayttaja')
          window.scrollTo({ top: 0 })
        }else{
          setState('Wrong')
        }
      })
  }

  const logout = () => {
    setUser('')
    setMuokkaus(false)
    axios.post('/rlh_logout')
    setState('Initial')
    setToken(null)
  }

  useEffect(() => {
    if(state === 'ShowLogin' || state === 'Wrong'){
      inputRef?.current?.focus()
    }
  }, [state])

  if(state === 'Logged'){
    return (
      <div id="kirjautumislinkki" onClick={logout}>
        Kirjaudu ulos
      </div> 
    )
  }else if(state !== 'Initial'){
    return (
      <div className={`login_panel ${state === 'Wrong' && 'wrong'}`}>
        { state === 'Loading'  ? 'Ladataan...' :
          <div>
            Salasana:
            <input type="password" value={salasana} onChange={e => setSalasana(e.target.value)} ref={inputRef} />
            <button onClick={kirjaudu}>Kirjaudu</button>
            { state === 'Wrong' && <div className="wrong_text">Väärä salasana!</div> }
          </div>  
        }
      </div>
    )
  }else{
    return ( 
      <div id="kirjautumislinkki" onClick={() => {setState('ShowLogin'); setTimeout(() => window.scroll({ top: 5000 }))}}>
        Kirjaudu sisään
      </div>
    ) 
  }
} 

type LoginProps = {
  setUser: (user:string)=>void,
  setMuokkaus: (oikeus:boolean)=>void
}
