
import React from 'react'
import './Henkilot.css'

export const Henkilot:React.FC = () => {
  const mobiili = window.matchMedia('(max-width: 600px)').matches
  
  const rivi = (tehtava:string, nimi:string, numero:string, email:string) => {
    return mobiili ? <tr key={numero}>
      <td>{nimi}<br/>{tehtava}</td>
      <td>{numero}<br/>{email}</td>
    </tr> : <tr key={numero}>
      <td>{tehtava}</td>
      <td>{nimi}</td>
      <td>{numero}</td>
      <td>{email}</td>
    </tr>
  }

  return (
    <div id="henkilot">
      <table>
        <tbody>
        {[
          rivi('Vanhimmiston pj', 'Unto Siikström', '0405923104', ''),
          rivi('Vanhin', 'Jouni Lund', '0405950594', ''),
          rivi('Nuorisotyöntekijä', 'Miika Pakarinen', '0504630511', 'miigaka@gmail.com'),
          rivi('Hallituksen pj', 'Kari Backman', '0405602798', 'kari.backman@pp.inet.fi')
        ]}
        </tbody>
      </table>
    </div>
   )
}