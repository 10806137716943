
import React from 'react'
import kyna from '../img/muokkaus.png'
import './Tapahtuma.css'

export type Tapahtuma = {
  id: string|null,
  otsikko: string,
  kuvaus: string,
  aika: Date,
  ilmestymisaika: Date
}

type TapahtumaProps = { 
  tapahtuma: Tapahtuma,
  muokkaus: boolean,
  muokkaaTapahtumaa: (tapahtuma:Tapahtuma)=>void
}

const uudenTapahtumanAika = () => {
  const aika = new Date()
  aika.setUTCHours(11)
  aika.setUTCMinutes(0)
  return aika
}

export const luoUusiTapahtuma = () => ({
  id: null,
  otsikko: '',
  kuvaus: '',
  aika: uudenTapahtumanAika(),
  ilmestymisaika: new Date()
})

const tulostaAika = (aika:Date, naytaKellonaika:boolean) => {
  const viikonPäivä = ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La', 'Su'][aika.getUTCDay()]
  const pvm = `${aika.getUTCDate()}.${aika.getUTCMonth()+1}.`
  const tunnit = aika.getUTCHours().toString().padStart(2, '0')
  const minuutit = aika.getUTCMinutes().toString().padStart(2, '0')
  const kellonaika = `klo ${tunnit}:${minuutit}`
  if(naytaKellonaika){
    return <span>{viikonPäivä} {pvm}&ensp;{kellonaika}</span>
  }else{
    return <span>{viikonPäivä} {pvm}</span>
  }
}

export const TapahtumaRivi: React.FC<TapahtumaProps> = ({ tapahtuma, muokkaus, muokkaaTapahtumaa }) => {
  const onkoKokous = tapahtuma.otsikko !== 'Ei kokousta'

  return (
    <div className="tapahtuma">
      { onkoKokous ? 
        <>
          <div className="tapahtumaaika">{tulostaAika(tapahtuma.aika, true)}</div>
          <span className="tapahtumaotsikko">{tapahtuma.otsikko}</span>
        </>
        : <div className="eikokousta">
            {tulostaAika(tapahtuma.aika, false)} 
            <span className="eikokoustateksti">Ei kokousta</span>
          </div>
      }
      { tapahtuma.kuvaus && 
          <div className="tapahtumakuvaus">
            {tapahtuma.kuvaus}
          </div>
      }
      { muokkaus &&
        <img className="tapahtumamuokkauspainike" src={kyna} alt="muokkauskynä"
          onClick={() => muokkaaTapahtumaa(tapahtuma)} title="Muokkaa" />
      }
    </div>
  )
}
