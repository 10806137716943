import React from 'react'
import './Tiedot.css'

export const Tiedot:React.FC = () => {

  return <div id="tiedot">
    <table>
      <tbody>
        <tr>
          <td>Seurakunta</td>
          <td>FI23 5389 0740 0353 65</td>
        </tr>
        <tr>
          <td>Lähetystyö</td>
          <td>FI98 5389 0740 0353 73</td>
        </tr>
      </tbody>
    </table>
  </div>
}