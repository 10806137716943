import React from 'react'
import './App.css'
import Tapahtumat from '../Tapahtumat/Tapahtumat'
import { useState, useCallback } from 'react'
import mainimg from '../img/sivu120.png'
import { Viesti, TyhjaViesti, ViestiRuutu } from './Viesti'
import LoginIlmoitus from '../Login/LoginIlmotus'
import { Henkilot } from './Henkilot'
import { YhteydenOtto } from '../Yhteydenotto/Yhteydenotto'
import { Tiedot } from './Tiedot'
import { Kirjautuminen } from '../Login/Login2'

const App: React.FC = () => {
  const [kayttaja, setKayttaja] = useState('')
  const [muokkaus, setMuokkaus] = useState(false)
  const [viesti, setViesti] = useState<Viesti>(TyhjaViesti)
  const [sisaltoLadattu, setLadattu] = useState(false)
  const [naytaYhdeydenotto, setNaytaYhteydenotto] = useState(false)

  const displayMessage = useCallback((teksti:string, virhe:boolean) => {
    setViesti({ teksti, virhe })
    if(!virhe){
      setTimeout(() => setViesti(TyhjaViesti), 4000)
    }
  }, [])

  return (
    <>
    <LoginIlmoitus {...{kayttaja, muokkaus}} />
    <div className="App">
      <div id="titlepanel">
        <img src={mainimg} id="mainimg" alt="Rukoushuoneen pääty ja risti" />
        <h1>Rautalammin<br />Helluntaiseurakunta</h1>
      </div>
      <ViestiRuutu viesti={viesti} />
      <div className="kaistaotsikko">TULEVAT TAPAHTUMAT</div>
      
      <div id="alaosa" className={ sisaltoLadattu ? '' : 'piilotettu' }>
        <Tapahtumat {...{ muokkaus, setLadattu }} />
        <div className="kaistaotsikko">PAIKKA</div>
        <p id="osoite">
          Mäkitie 1, 77700 Rautalampi
        </p>
        <iframe id="kartta" title="kartta" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1834.7227830292175!2d26.828057446429412!3d62.622493214598855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4684e7494243a9d7%3A0x1605e7db0f5853ea!2sRautalammin%20Helluntaiseurakunta!5e0!3m2!1sfi!2sfi!4v1596020018529!5m2!1sfi!2sfi" aria-hidden="false"></iframe>
        { naytaYhdeydenotto ? 
          <div>
            <div id="yhteysotsikko" className="kaistaotsikko">OTA YHTEYTTÄ</div>
            <YhteydenOtto />
          </div>
          : <button onClick={() => setNaytaYhteydenotto(true)}>Ota yhteyttä</button>
        }
        <div className="kaistaotsikko" id="henkilototsikko">HENKILÖT</div>
        <Henkilot />
        <div className="kaistaotsikko">TILIT</div>
        <Tiedot />
        <div id="userarea">
          <Kirjautuminen {...{ 
            setUser: useCallback(setKayttaja, []), 
            setMuokkaus: useCallback(setMuokkaus, [])
          }}/>
        </div>
      </div>
      { !sisaltoLadattu && <div id="ladataan">Ladataan...</div> }
    </div>
    </>
  )
}

export default App
